.cs-card.cs-style1 {
  position: relative;
  border-radius: 15px;


  &:hover {
    filter: drop-shadow(0px 0px 30px rgba(#6C2C78, 0.5));
  }
  img {
    width: 100%;
    height: 100%;

    border-radius: inherit;
  }
  // .cs-card_overlay {

  //   background: #000000;
  //   opacity: 0.5;
  // }
  .cs-card_info_service {
    position: absolute;
    padding: 45px 25px 0;
    top: 0;
    left: 0;
    span {
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-top: 250px;
    }
  }
  .cs-card_title_card {
    font-size: 22px;
    margin: -20px 30px;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 1400px) {
  .cs-card.cs-style1 .cs-card_title_card {
    font-size: 20px;
    margin: -20px 30px;
  }
  .cs-card.cs-style1 .cs-card_info_service {
    padding: 40px 18px 0;

  }
}

@media screen and (max-width: 1199px) {
  .cs-card.cs-style1 .cs-card_title_card {
    font-size: 24px;

  }
  .cs-card.cs-style1 .cs-card_info_service {
    padding: 50px 30px 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}



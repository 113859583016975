.cs-card.cs-style1 {
    position: relative;
    border-radius: 15px;


    &:hover {
      filter: drop-shadow(0px 0px 30px rgba(#6C2C78, 0.5));
    }
    img {
      width: 100%;
      height: 100%;

      border-radius: inherit;
    }
    .cs-card_overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: #000000;
      opacity: 0.5;
    }
    .cs-card_info {
      position: absolute;
      padding: 45px 25px 0;
      top: 0;
      left: 0;
      span {
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-bottom: 10px;
      }
    }
    .cs-card_title {
      font-size: 22px;
      margin: 0;
      line-height: 1.5em;
    }
  }

  @media screen and (max-width: 1400px) {
    .cs-card.cs-style1 .cs-card_title {
      font-size: 20px;
    }
    .cs-card.cs-style1 .cs-card_info {
      padding: 40px 18px 0;
    }
  }

  @media screen and (max-width: 1199px) {
    .cs-card.cs-style1 .cs-card_title {
      font-size: 24px;
    }
    .cs-card.cs-style1 .cs-card_info {
      padding: 40px 30px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }
